<template>
  <div class="order-view overflow-hidden">
    <!-- Vendor Detail Row -->
    <div>
      <!-- Project Name -->
      <div class="text-uppercase">
        <p class="m-0 text-gray text-caption w-100">PROJECT NAME</p>
        <h2 class="w-100 text-truncate">
          {{ order.project_name }}
        </h2>
      </div>
      <div v-if="admin" class="d-flex align-items-center">
        <img
          src="@/assets/logo.png"
          class="rounded-circle mr-3"
          width="30"
          height="30"
        />
        <div>
          <p class="m-0 text-gray text-uppercase">SALES PERSONNEL</p>
          <h5 class="font-style-primary">
            {{ order.user.information | userFullName }}
          </h5>
        </div>
      </div>
    </div>

    <!-- Order Detail Row -->
    <div class="mt-3">
      <div class="row">
        <!-- RFQ ID -->
        <div class="col-6 col-md-2">
          <AppTextLabeled label="RFQ ID." class="font-weight-5">
            {{ order.id | rfqFormat }}
          </AppTextLabeled>
        </div>
        <!-- PO NO. Number -->
        <div class="col-6 col-md-2">
          <AppTextLabeled label="Po No." class="font-weight-5">
            {{ order.po_number || 'N/A' }}
          </AppTextLabeled>
        </div>
        <!-- Status -->
        <div class="col-12 col-md-2 mb-2 mt-2 mb-md-0 mt--md-0">
          <AppTextLabeled label="STATUS" class="font-weight-4">
            <span
              :class="
                order.status === 'completed'
                  ? 'bg-success text-white'
                  : 'bg-special-light-3'
              "
              class="m-0 p-1 text-caption rounded-sm"
            >
              {{ order.status | toTitleCase }}
            </span>
          </AppTextLabeled>
        </div>
        <!-- Order Date -->
        <div class="col-6 col-md-2">
          <AppTextLabeled label="Order Date" class="font-weight-5">
            {{ order.created_at | readableDate(false) }}
          </AppTextLabeled>
        </div>
        <!-- Bidding End Date -->
        <div class="col-6 col-md-2">
          <AppTextLabeled label="End Bidding Date" class="font-weight-5">
            {{ order.bidding_end_date | readableDate(false, true) }}
          </AppTextLabeled>
        </div>
        <!-- Delivery Date -->
        <div class="col mt-3 mt-md-0">
          <AppTextLabeled label="Delivery Date" class="font-weight-5">
            {{ order.delivery_date | readableDate(false, true) }}
          </AppTextLabeled>
        </div>
      </div>
      <div class="row mt-3">
        <!-- Delviery Option -->
        <div class="col-12 col-md-2">
          <AppTextLabeled label="DELIVERY OPTION" class="font-weight-5">
            {{
              order.delivery_type === 'pick up' ? 'Pick Up' : 'Ship to address'
            }}
          </AppTextLabeled>
        </div>
        <!-- Address -->
        <div class="col-12 col-md-5">
          <AppTextLabeled label="ADDRESS" class="font-weight-5">
            {{
              `${order.address}, ${order.city}, ${order.state} ${order.zip_code}`
            }}
          </AppTextLabeled>
        </div>
        <!-- Truck -->
        <div class="col-12 col-md mt-3 mt-md-0">
          <AppTextLabeled label="TRUCK" class="font-weight-5">
            <!-- Forklift -->
            <ul v-if="order.has_forklift" class="pl-4 d-inline-block">
              <li>Piggy Back Forklift</li>
            </ul>
            <!-- Union Drivers -->
            <ul v-if="order.is_union_delivery" class="pl-4 d-inline-block">
              <li>Union Delivery</li>
            </ul>
          </AppTextLabeled>
        </div>
      </div>
      <!-- Delivery note -->
      <AppTextLabeled
        label="DELIVERY NOTES"
        class="mt-3 font-weight-5 hoverable"
        @click="deliveryNotefull = true"
      >
        {{ order.delivery_note | truncateText(350) }}
      </AppTextLabeled>

      <AppModal v-model="deliveryNotefull" width="600px">
        <div class="p-2">
          <div class="d-flex align-items-center justify-content-between">
            <h4>DELIVERY NOTE</h4>
            <AppIcon
              hoverable
              name="close"
              @click="deliveryNotefull = false"
            ></AppIcon>
          </div>

          <p class="mt-3">
            {{ order.delivery_note || 'N/A' }}
          </p>

          <AppBtn
            class="d-block mt-2 ml-auto mr-auto"
            @click="deliveryNotefull = false"
          >
            Close
          </AppBtn>
        </div>
      </AppModal>
    </div>

    <!-- Product Table -->
    <div
      v-if="!order.products || order.products.length <= 0"
      class="mt-5 mb-5 p-3 d-flex justify-content-center"
    >
      <p class="m-0 text-gray">No Items to show</p>
    </div>
    <div v-else class="mt-5 mb-5">
      <!--Header  -->
      <div :class="admin ? 'bg-dark text-light' : 'bg-primary'">
        <div class="row d-none d-md-flex">
          <div class="col-3"><p class="m-2 pl-2">Product</p></div>
          <div class="col-3"><p class="m-2">Variant</p></div>
          <div class="col-2"><p class="m-2">Qty</p></div>
        </div>
      </div>
      <!-- Body -->
      <div
        v-for="(item, index) in order.products"
        :key="`product-item-${index}`"
        class="p-2 mb-3 shadow-40"
      >
        <div class="row">
          <div class="col-12 col-md-3">
            <div class="row p-1">
              <div class="col-3">
                <AppMediaViewer
                  aspect-ratio="1/1"
                  src=""
                  width="100%"
                  class="rounded-circle"
                ></AppMediaViewer>
              </div>
              <div class="col-9">
                <span>{{ item.product_name }}</span>
              </div>
            </div>
          </div>
          <div
            class="
              mt-2 mt-md-0
              offset-3 offset-md-0
              col-6 col-md-3
              d-flex
              align-items-center
            "
          >
            <span class="mr-1">{{
              item.variant ? item.variant.name : 'No Variant'
            }}</span>
          </div>
          <div
            class="
              mt-2 mt-md-0
              offset-3 offset-md-0
              col-6 col-md-2
              d-flex
              align-items-center
            "
          >
            <div v-if="item.quantity_description">
              <span>
                {{
                  `${item.quantity} ${item.quantity_description.quantity.name}`
                }}
              </span>
              <span class="d-inline-block text-caption"
                >({{
                  `${
                    parseInt(item.quantity) *
                    item.quantity_description.conversion
                  } pieces`
                }})</span
              >
            </div>
            <span v-else>{{ `${item.quantity} pcs` }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Vendors Table -->
    <p class="m-0">RFQ Sent to:</p>
    <div
      v-if="!order.requests || order.requests.length <= 0"
      class="mt-5 mb-5 p-3 d-flex justify-content-center"
    >
      <p class="m-0 text-gray">Sent to all vendors</p>
    </div>
    <div v-else class="mt-5 mb-5">
      <!--Header  -->
      <div :class="admin ? 'bg-dark text-light' : 'bg-primary'">
        <div class="row d-none d-md-flex">
          <div class="col-3"><p class="m-2 pl-2">Company</p></div>
          <div class="col-3"><p class="m-2">Address</p></div>
        </div>
      </div>
      <!-- Body -->
      <div
        v-for="(item, index) in order.requests"
        :key="`requests-item-${index}`"
        class="p-2 mb-3 shadow-40"
      >
        <div class="row">
          <div
            class="
              mt-2 mt-md-0
              offset-3 offset-md-0
              col-6 col-md-3
              d-flex
              align-items-center
            "
          >
            <span class="mr-1">{{ item.company.name }}</span>
          </div>
          <div
            class="
              mt-2 mt-md-0
              offset-3 offset-md-0
              col-6 col-md-2
              d-flex
              align-items-center
            "
          >
            {{ item.company.address }}
          </div>
        </div>
      </div>
    </div>

    <!-- Action Btns -->
    <template v-if="![STATUS.COMPLETED.value].includes(order.status)">
      <div
        v-if="
          [
            STATUS.REQUEST_CANCELLED.value,
            STATUS.REQUEST_DECLINED.value,
          ].includes(order.status)
        "
        class="d-flex justify-content-end"
      >
        <div class="p-2 bg-secondary-light-3 text-white">
          <span class="text-uppercase"> Request {{ order.status }} </span>
        </div>
      </div>
      <div
        v-else
        class="mt-4 d-flex flex-column flex-md-row justify-content-end"
      >
        <AppBtn
          v-if="isDeleteAllowed"
          class="ml-md-3 mt-2 mt-md-0"
          outline
          :loading="loading"
          @click="cancelRfq"
        >
          Cancel Request
        </AppBtn>
        <AppBtn
          v-if="isEditAllowed"
          class="ml-md-3 mt-2 mt-md-0"
          :loading="loading"
          @click="$emit('edit')"
        >
          Edit & Resend
        </AppBtn>

        <template v-if="[STATUS.REQUEST_PENDING.value].includes(order.status)">
          <AppBtn
            outline
            :loading="loading"
            @click="declineRfq"
            v-if="admin"
            class="ml-md-3 mt-2 mt-md-0"
          >
            Decline
          </AppBtn>
          <AppBtn
            v-if="admin"
            class="ml-md-3 mt-2 mt-md-0"
            :loading="loading"
            @click="approveRfq"
          >
            Approve RFQ
          </AppBtn>
        </template>

        <AppBtn
          class="d-md-none mt-5"
          :loading="loading"
          @click="$emit('clone')"
        >
          Clone RFQ
        </AppBtn>
      </div>
    </template>

    <ActionModal
      v-model="modal.show"
      :state="modal.state"
      :header="modal.header"
      :description="modal.description"
      @confirm="modal.action"
    ></ActionModal>
  </div>
</template>

<script>
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppTextLabeled from '@/shared/elements/AppTextLabeled.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import ActionModal from '../../Partial/Modals/ActionModal.vue';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import _strings from '@/shared/mixins/_strings';
import AppModal from '../../../shared/elements/AppModal.vue';
import AppIcon from '../../../shared/elements/AppIcon.vue';
import { isRoleAllowed } from '@/router/AuthorizationGate';
import { group_names } from '@/shared/constants/PersonaConstants';
import { STATUS } from '@/shared/constants/OrderConstants';

export default {
  name: 'OrderView',

  components: {
    AppMediaViewer,
    AppTextLabeled,
    AppBtn,
    ActionModal,
    AppModal,
    AppIcon,
  },

  mixins: [_readableDateTime, _rfqFormat, _strings],

  props: {
    admin: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    order: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      STATUS,
      deliveryNotefull: false,
      modal: {
        show: false,
        state: 'confirm',
        header: '',
        description: '',
        action: () => {},
      },
    };
  },

  computed: {
    isDeleteAllowed() {
      if (Object.keys(this.order).length === 0) {
        return false;
      }

      const { buyer, admin, owner } = group_names;
      const isAdmin = isRoleAllowed([admin, owner]);

      const { user } = this.order;
      const { id } = this.$store.getters['auth/user'];
      const isSameID = user.id === id;
      const isUser = isRoleAllowed([buyer]);

      return (
        ((isAdmin && this.order.status !== 'pending') ||
          (isUser && isSameID)) &&
        this.order.has_delivered === false
      );
    },
    isEditAllowed() {
      const { user } = this.order;
      const { id } = this.$store.getters['auth/user'];
      const isSameID = user.id === id;

      // const { buyer, admin, estimator } = group_names;
      return isSameID && this.order.status === 'pending';
    },
  },

  filters: {
    userFullName(value) {
      if (value && (value.first_name || value.last_name)) {
        return `${value.first_name} ${value.last_name} `;
      } else {
        return 'N/A';
      }
    },
    truncateText(value, maxChars = 100) {
      if (!value) {
        return 'N/A';
      } else if (value.length <= maxChars || maxChars <= 0) {
        return value;
      } else {
        return `${value.slice(0, maxChars + 1)}...`;
      }
    },
  },

  methods: {
    approveRfq() {
      this.modal = {
        show: true,
        state: 'confirm',
        header: 'Confirm Approval',
        description:
          'You are going to approve this RFQ. Click "confirm" to proceed',
        action: () => {
          this.$emit('approve');
        },
      };
    },
    declineRfq() {
      this.modal = {
        show: true,
        state: 'delete',
        header: 'Confirm Decline',
        description:
          'You are going to decline this RFQ. Click "confirm" to proceed',
        action: () => {
          this.$emit('decline');
        },
      };
    },
    cancelRfq() {
      this.modal = {
        show: true,
        header: 'Confirm Cancel',
        description:
          'You are going to cancel this RFQ. Click "confirm" to proceed',
        action: () => {
          this.$emit('decline');
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';

.order-view {
  width: 1000px;
  @include media-lg('below') {
    width: 100%;
  }
  &-total {
    width: 500px;
  }
}
</style>
